<template>
    <section class="op-ciclos-finalizados overflow-auto scroll-none">
        <navbar-admin />
        <div class="row mx-0">
            <div class="col-12 col-lg-8">
                <div class="row mx-0 my-4">
                    <div class="col-12 col-lg-5">
                        <div class="d-middle">
                            <div class="wh-50 bg-general text-white d-middle-center rounded-circle">
                                <i class="icon-chart-bar f-20" />
                            </div>
                            <div class="ml-3">
                                <p class="f-500 mb-2">Oferta</p>
                                <p class="f-14 f-500 mb-1">
                                    Cierre:
                                    <span class="f-300"> 15 Ene. 2020 </span>
                                </p>
                                <p class="f-14 f-500">
                                    Cierre planeado:
                                    <span class="f-300"> 13 Ene. 2020 </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <p class="f-12">Encargado de finalizarlo</p>
                        <div class="bg-f5 border rounded-pill d-middle tres-puntos my-1">
                            <img src="/img/pruebas/person1.png" alt="" class="wh-26 rounded-pill bg-white">
                            <p class="ml-2 f-14 tres-puntos">Juan Camilo Ramirez</p>
                        </div>
                        <span class="rounded-pill text-white bg-red f-12 px-2 py-1">
                            2 Días después de la fecha
                        </span>
                    </div>
                </div>
                <!-- Anotaciones -->
                <titulo-divisor titulo="Anotaciones" />
                <div class="row mx-0">
                    <p class="pl-3 f-14 f-500 mb-2">Observaciones del avance</p>
                    <p class="pl-3 f-12 lh-15 mb-3">I wouldn't try to throw myself away, If you asked me, I'd say, To be careful, my love, At death we'll leave the same, If you want me to stay, Don't keep me waiting for it, We can't change the things we can't control, It's summer somewhere, so you shouldn't be so cold, But you can't even look me in the eye if you tried, We can try, we can try, I see us dancing by ourselves, We do it better with no one around, yeah, Just you in my imagination, yeah, In my imagination, oh oh</p>
                    <p class="pl-3 f-14 f-500 mb-2">Estrategia</p>
                    <p class="pl-3 f-12">I wouldn't try to throw myself away, If you asked me, I'd say, To be careful, my love, At death we'll leave the same, If you want me to stay, Don't keep me waiting for it, We can't change the things we can't control, It's summer somewhere, so you shouldn't be so cold, But you can't even look me in the eye if you tried, We can try, we can try, I see us dancing by ourselves, We do it better with no one around, yeah, Just you in my imagination, yeah, In my imagination, oh oh</p>
                </div>
                <!-- Documentacion -->
                <titulo-divisor titulo="Documentación" />
                <div class="row mx-0">
                    <div class="col-6 col-lg-4 mb-3">
                        <div class="bg-f5 border br-4 d-flex tres-puntos">
                            <i class="icon-pdf text-red f-20" />
                            <p class="tres-puntos f-12 my-auto">Presentación de la propuesta</p>
                        </div>
                    </div>
                    <div class="col-6 col-lg-4 mb-3">
                        <div class="bg-f5 border br-4 d-flex tres-puntos">
                            <i class="icon-image text-success f-20" />
                            <p class="tres-puntos f-12 my-auto">Esquema del proceso</p>
                        </div>
                    </div>
                    <div class="col-6 col-lg-4 mb-3">
                        <div class="bg-f5 border br-4 d-flex tres-puntos">
                            <i class="icon-pdf text-red f-20" />
                            <p class="tres-puntos f-12 my-auto">Presentación de la propuesta</p>
                        </div>
                    </div>
                </div>
                <!-- datos adicionales -->
                <section-datos-adicionales :info="listadoDatosAdicionales" />
                <!-- archivos extras -->
                <section-extra-files
                :filesList="extraFilesList"
                @addFile="agregarArchivoCiclo"
                @changeFileName="cambiarNombreArchivo"
                @deleteFile="eliminarArchivoExtra"
                />
            </div>
            <div class="col-12 col-lg-4 border br-4">
                <div class="contenedor-mensajes">
                    <div class="row bg-general br-t-4">
                        <p class="col-12 text-center text-white py-2 f-500">Comentarios</p>
                    </div>
                    <div class="row my-3">
                        <div class="col-auto">
                            <img src="/img/pruebas/person1.png" alt="" class="wh-48 rounded-circle bg-white border">
                        </div>
                        <div class="col pl-0">
                            <el-input v-model="inputComentario" type="textarea" placeholder="Añade un comentario" :autosize="{ minRows: 4, maxRows: 4}" />
                        </div>
                        <div class="col-12 text-right mt-2">
                            <button class="btn btn-general f-12">Comentar</button>
                        </div>
                    </div>
                    <div class="listado-comentarios py-3">
                        <div v-for="(data,i) in 5" :key="i" class="row mb-4 mx-0">
                            <div class="col-auto">
                                <img src="/img/pruebas/person1.png" alt="" class="wh-48 rounded-circle bg-white border">
                            </div>
                            <div class="col pl-0">
                                <div class="comentario bg-f5 border br-4 p-2">
                                    <p class="f-500 f-12 d-middle">
                                        Ana Sofia Melgar
                                        <span class="f-10 f-300 ml-auto font-italic">10/03/19 - 11:25 am</span>
                                    </p>
                                    <p class="f-10 lh-19">
                                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- partials -->
        <modal-cambiar-nombre-adjunto ref="modalCambiarNombreAdjunto" />
        <modal-eliminar ref="modalEliminarArchivoExtra" title="Eliminar archivo extra" mensaje="¿Está seguro que quiere eliminar esta archivo?" mensaje2 />
        <modal-adjuntos ref="modalAgregarArchivoCiclos" />
    </section>
</template>

<script>
export default {
    components:{
        modalCambiarNombreAdjunto: () => import('./partials/modalCambiarNombreAdjunto')
    },
    data(){
        return{
            inputComentario: '',
            listadoDatosAdicionales: [
                {
                    nombreDato:'Lugar de presentación',
                    descripcion:"Centro de negocios internacionales",
                },
                {
                    nombreDato:'N° de personas presentes',
                    descripcion:"32",
                },
                {
                    nombreDato:'Tipo de presentación',
                    descripcion:"Animada",
                }
            ],
            extraFilesList:[
                {
                    id: 1,
                    fileName: 'Presentación de la propuesta',
                    fileType: 0,
                },
                {
                    id: 2,
                    fileName: 'Esquema del proceso',
                    fileType: 1,
                },
            ]
        }
    },
    methods:{
        agregarArchivoCiclo(){
            this.$refs.modalAgregarArchivoCiclos.toggle()
        },
        cambiarNombreArchivo(){
            this.$refs.modalCambiarNombreAdjunto.toggle()
        },
        eliminarArchivoExtra(){
            this.$refs.modalEliminarArchivoExtra.toggle()
        },
    }
}
</script>

<style lang="scss" scoped>
.op-ciclos-finalizados{
    height: calc(100vh - 188px);
    .contenedor-mensajes{
        .listado-comentarios{
            // height: calc(100vh - 410px);
            .comentario{
                position: relative;
                &::before{
                    content: '';
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    background: #f5f5f5;
                    transform: rotate( 45deg );
                    left: -6px;
                    top: 17px;
                    border-bottom: 1px solid #dee2e6;
                    border-left: 1px solid #dee2e6;
                }
            }
        }
    }
    
}
</style>